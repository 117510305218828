<template>
  <div>
    <div
      id="kt_subheader"
      class="subheader py-2 py-lg-4 mb-5 gutter-b subheader-solid"
    >
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      >
        <!--begin::Info-->
        <div class="d-flex align-items-center flex-wrap mr-2">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
            Product Management
          </h5>
          <!--end::Page Title-->
          <!--begin::Action-->
          <div
            class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
          ></div>

          <span class="text-muted font-weight-bold mr-4"> </span>
          <div
            class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
          ></div>

          <!--end::Action-->
        </div>

        <div class="d-flex align-items-center"></div>
        <!--end::Info-->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="card card-custom bg-alert">
          <v-parallax height="100" dark src="/media/header-green.jpg">
            <div class="display-4 font-weight-thin mt-4">
              <strong> Item Name </strong>
            </div>
            <v-row align="center" justify="center">
              <v-col class="text-right" cols="12">
                <button type="button" class="ml-3 btn btn-danger">
                  Delete Item
                </button>
              </v-col>
            </v-row>
          </v-parallax>

          <div class="card-body">
            <div class="row">
              <div class="col-lg-6">
                <div class=" row">
                  <label class="col-5 "> <Strong>Product </Strong></label>
                  <div class="col-7">
                    Blue Shirt
                  </div>
                </div>
                <div class=" row">
                  <label class="col-5 "> <Strong>Attribute Set </Strong></label>
                  <div class="col-7">
                    Clothing 2
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class=" row">
                  <label class="col-5 "> <Strong>Date Created </Strong></label>
                  <div class="col-7">
                    10/17/2019
                  </div>
                </div>

                <div class=" row">
                  <label class="col-5 "> <Strong>Created by </Strong></label>
                  <div class="col-7">
                    John Smith
                  </div>
                </div>
              </div>
            </div>

            <v-spacer> </v-spacer>
            <div class="form-group row">
              <label class="col-5 col-form-label">Status</label>
              <div class="col-7">
                <select id="exampleFormControlSelect1" class="form-control">
                  <option>Choose Status</option>
                  <option>New</option>
                  <option>Pending</option>
                  <option>Discontinued</option>
                  <option>Active</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-5 col-form-label">Name</label>
              <div class="col-7">
                <input
                  id="name"
                  v-model="name"
                  class="form-control"
                  type="text"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-5 col-form-label">SKU #</label>
              <div class="col-7">
                <input
                  id="sku"
                  v-model="sku"
                  class="form-control"
                  type="text"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-5 col-form-label">UPC</label>
              <div class="col-7">
                <input
                  id="upc"
                  v-model="upc"
                  class="form-control"
                  type="text"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-5 col-form-label">UPC 2</label>
              <div class="col-7">
                <input
                  id="name"
                  v-model="upc2"
                  class="form-control"
                  type="text"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-5 col-form-label">ISBN</label>
              <div class="col-7">
                <input
                  id="isbn"
                  v-model="isbn"
                  class="form-control"
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <v-card>
          <v-tabs horizontal>
            <v-tab>
              <v-icon left>mdi-access-point</v-icon>
              Main Image
            </v-tab>
            <v-tab>
              <v-icon left>mdi-access-point</v-icon>
              Additional Images
            </v-tab>
            <v-tab>
              <v-icon left>mdi-access-point</v-icon>
              Video Links
            </v-tab>

            <v-tab-item>
              <v-card class="mx-auto h-100">
                <v-img src="/media/stock-600x600/img-44.jpg">
                  <v-btn> Delete Item </v-btn>
                </v-img>

                <v-card-text>
                  <h4>Use product Image or upload New.</h4>
                </v-card-text>

                <v-card-actions>
                  <v-btn text>Main Image</v-btn>

                  <v-file-input show-size label="Upload Image"></v-file-input>

                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-row>
                  <v-col cols="6" sm="4">
                    <v-img
                      src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"
                      gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                    ></v-img>
                  </v-col>

                  <v-col cols="6" sm="4">
                    <v-img
                      src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"
                    >
                      <div class="fill-height bottom-gradient"></div>
                    </v-img>
                  </v-col>

                  <v-col cols="6" sm="4">
                    <v-img
                      src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"
                    >
                      <div class="fill-height repeating-gradient"></div>
                    </v-img>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="4">
                    <v-img
                      src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"
                      gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                    ></v-img>
                  </v-col>

                  <v-col cols="6" sm="4">
                    <v-img
                      src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"
                    >
                      <div class="fill-height bottom-gradient"></div>
                    </v-img>
                  </v-col>

                  <v-col cols="6" sm="4">
                    <v-img
                      src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"
                    >
                      <div class="fill-height repeating-gradient"></div>
                    </v-img>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="4">
                    <v-img
                      src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"
                      gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                    ></v-img>
                  </v-col>

                  <v-col cols="6" sm="4">
                    <v-img
                      src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"
                    >
                      <div class="fill-height bottom-gradient"></div>
                    </v-img>
                  </v-col>

                  <v-col cols="6" sm="4">
                    <v-img
                      src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"
                    >
                      <div class="fill-height repeating-gradient"></div>
                    </v-img>
                  </v-col>
                </v-row>
                <v-file-input
                  show-size
                  label="Upload additional Image"
                ></v-file-input>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <div class="form-group row">
                    <label class="col-3 col-form-label text-dark"
                      >Youtube Link</label
                    >
                    <div class="col-7">
                      <input
                        id="Youtube"
                        v-model="youtube"
                        class="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3 col-form-label text-dark"
                      >Vimeo Link</label
                    >
                    <div class="col-7">
                      <input
                        id="vimeo"
                        v-model="vimeo"
                        class="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="card card-custom">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group row">
                  <label class="col-5 col-form-label">Secondary Name</label>
                  <div class="col-7">
                    <input
                      id="Price"
                      v-model="price"
                      class="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-5 col-form-label">URL Key</label>
                  <div class="col-7">
                    <input
                      id="url"
                      v-model="url"
                      class="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-5 col-form-label">Weight LBS</label>
                  <div class="col-7">
                    <input
                      id="number"
                      v-model="number"
                      class="form-control"
                      type="number"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-5 col-form-label">Length</label>
                  <div class="col-7">
                    <input
                      id="number"
                      v-model="number"
                      class="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-5 col-form-label">Width</label>
                  <div class="col-7">
                    <input
                      id="number"
                      v-model="number"
                      class="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-5 col-form-label">Height</label>
                  <div class="col-7">
                    <input
                      id="number"
                      v-model="number"
                      class="form-control"
                      type="text"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-12 col-form-label"> Shipping Info</label>

                  <div class="col-12">
                    <ckeditor
                      :editor="editor"
                      :config="editorConfig"
                    ></ckeditor>

                    <pre class="mt-3 mb-0"></pre>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-12 col-form-label"> Marketing text</label>

                  <div class="col-12">
                    <ckeditor
                      :editor="editor"
                      :config="editorConfig"
                    ></ckeditor>

                    <pre class="mt-3 mb-0"></pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="card card-custom">
          <div class="card-header">
            <div class="card-title">
              Entering content here will override data from Parent Product
            </div>
          </div>
          <div class="card-body px-3">
            <div class="form-group row">
              <label class="col-12 col-form-label"> Short Description</label>

              <div class="col-12">
                <ckeditor :editor="editor" :config="editorConfig"></ckeditor>

                <pre class="mt-3 mb-0"></pre>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-form-label"> Long Description</label>

              <div class="col-12">
                <ckeditor :editor="editor" :config="editorConfig"></ckeditor>

                <pre class="mt-3 mb-0"></pre>
              </div>
            </div>
          </div>
        </div>

        <attributeCustom> </attributeCustom>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import attributeCustom from "../../../components/pim/attributesets/Clothing1-set.vue";

export default {
  components: {
    attributeCustom,
  },

  mixins: [validationMixin],
  data: () => ({
    selected: null,

    chips: ["Fall2020", "Shirts", "mens camo"],

    editor: ClassicEditor,

    editorConfig: {
      toolbar: {
        items: [
          "heading",
          "|",
          "bold",
          "italic",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          "undo",
          "redo",
        ],
      },
    },
    options: [
      { value: null, text: "New" },
      { value: "draft", text: "Draft" },
      { value: "awaiting", text: "Awaiting Approval" },
      { value: "active", text: "Active " },
      { value: "disco", text: "Discontinued" },
      { value: "eol", text: "End of Life" },
    ],
    logic: ["Active", "Pending", "Discontinued", "NOT Discontinued"],

    dialog: false,
    dialog2: false,
  }),
};
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}
</style>
